<div class="text-content">1. Importe la librería de pago Android.
</div>
<div class="code-content-xs">
    <!--<iframe src="https://pastebin.com/embed_iframe/Qmwxkey3"
        style="border:none;width:100%;height:75px;padding-bottom:10px;"></iframe>-->
    <ng-gist userName="synapdevelopment" gistId="17ad2400307ddb2683d4bccbfa894d15"
        fileName="merchant-android-mainactivity-imports.java"></ng-gist>
</div>
<div class="text-content">2. Agregue la variable que permite el acceso a las funcionalidades
    de la librería de pago en el mainactivity.
</div>
<div class="code-content-xs">
    <!--<iframe src="https://pastebin.com/embed_iframe/kgKw4fDn"
        style="border:none;width:100%;height:75px;padding-bottom:10px;"></iframe>-->
    <ng-gist userName="synapdevelopment" gistId="7dccc6528ee69937d9c172f22899f550"
        fileName="merchant-android-mainactivity-properties-paymentwidget.java"></ng-gist>
</div>
<div class="text-content">3. Agregue el control "FrameLayout" como contenedor del formulario
    de pago y asócielo en el mainactivity.
</div>
<div class="code-content-xs">
    <!--<iframe src="https://pastebin.com/embed_iframe/tU6cUh6m"
        style="border:none;width:100%;height:75px;padding-bottom:10px;"></iframe>-->
    <ng-gist userName="synapdevelopment" gistId="a089aee31e2be5f4303582ffaefb81db"
        fileName="merchant-android-mainactivity-properties-synapform.java"></ng-gist>
</div>
<aside class="note"><b>Nota:</b> Las dimensiones del contenedor "synapForm" deben ser de 320dp de
    ancho por 220dp de alto.
    <br>Las dimensiones del contenedor "SynapWebView" deben ser de toda la pantalla (ancho y alto).
</aside>
<div class="text-content">4. Agregue el control "Button" como botón que solicitará el pago y
    asócielo en el mainactivity.
</div>
<div class="code-content-xs">
    <!--<iframe src="https://pastebin.com/embed_iframe/gM2eCs5S"
        style="border:none;width:100%;height:75px;padding-bottom:10px;"></iframe>-->
    <ng-gist userName="synapdevelopment" gistId="cffe4573f7640372933bfeb0ef5e086b"
        fileName="merchant-android-mainactivity-properties-synapbutton.java"></ng-gist>
</div>
<div class="text-content">5. Oculte inicialmente el contenedor del formulario de pago y el
    botón de pago.
</div>
<div class="code-content">
    <!--<iframe src="https://pastebin.com/embed_iframe/TFRxErnd"
        style="border:none;width:100%;height:400px;padding-bottom:10px;"></iframe>-->
    <ng-gist userName="synapdevelopment" gistId="32ebaf2adcfc8af09f171267bfe68070"
        fileName="merchant-android-mainactivity-func-oncreate.java"></ng-gist>
</div>
<div class="text-content">6. Inicialice y configure el formulario de pago (visualmente, el
    ambiente de procesamiento de pago y la gestión visual de la respuesta).
</div>
<div class="code-content">
    <!--<iframe src="https://pastebin.com/embed_iframe/psuSFJDy"
        style="border:none;width:100%;height:600px;padding-bottom:10px;"></iframe>-->
    <ng-gist userName="synapdevelopment" gistId="ed5bf7a5f4a81eade275827c965d7b5a"
        fileName="merchant-android-mainactivity-func-startpayment.java"></ng-gist>
</div>
<div class="text-content">7. Setee los datos de la orden o pedido.
</div>
<div class="code-content">
    <!--<iframe src="https://pastebin.com/embed_iframe/EmVKbGAp"
        style="border:none;width:100%;height:600px;padding-bottom:10px;"></iframe>-->
    <ng-gist userName="synapdevelopment" gistId="0c20a00a07fb35a7d643a07445cafeb3"
        fileName="merchant-android-mainactivity-func-buildtransaction.java"></ng-gist>
</div>
<div class="text-content">8. Setee los datos de autenticación. Para más información diríjase
    a la sección de <a routerLink="/security">"Seguridad".</a>
</div>
<div class="code-content">
    <!--<iframe src="https://pastebin.com/embed_iframe/62WYK02C"
        style="border:none;width:100%;height:400px;padding-bottom:10px;"></iframe>-->
    <ng-gist userName="synapdevelopment" gistId="a4af83a6b3ef76b2b9b5ccd3dc63e485"
        fileName="merchant-android-mainactivity-func-buildauthenticator.java"></ng-gist>
</div>
<aside class="note"><b>Nota:</b> El APIKey y SignatureKey están disponibles en la plataforma
    de gestión de Synapsis (Synap - Control)
</aside>
<div class="text-content">9. Las siguientes funciones son utilitarias y deben ser
    utilizadas solo como "referencia".
</div>
<div class="code-content">
    <!--<iframe src="https://pastebin.com/embed_iframe/2RD5g599"
        style="border:none;width:100%;height:600px;padding-bottom:10px;"></iframe>-->
    <ng-gist userName="synapdevelopment" gistId="63cec88dc1cf35462d879e75cbb8aefe"
        fileName="merchant-android-mainactivity-func-utilities.java"></ng-gist>
</div>