<div class="page-container">
    <div class="row center-xs">
        <div class="col-xs-12 col-sm-11 col-md-9">
            <div class="row">
                <div class="col-xs-12">
                    <div class="text-container">
                        <div class="paragraph">
                            <h1>BOTON iOS</h1>
                            <div class="text-content-bigger">El comercio puede conectarse con nuestras librerías móviles
                                para iOS en menos de 2 horas.
                                Las librerías móviles están creadas en lenguajes nativos, permitiendo la integración
                                desde cualquier app móvil iOS.
                            </div>
                            <div class="text-content-bigger">El formulario de pago se crea dinámicamente, haciendo
                                segura la captura y el envío de los datos de tarjeta.
                                El comercio decide cómo y dónde mostrar el formulario de pago, permitiendo continuar con
                                la experiencia del cliente en el app.
                            </div>
                            <div class="text-content-bigger">El pago es solicitado al procesador de pagos y la respuesta
                                es mostrada al cliente sin errores y sin demoras.
                                El comercio recibe la notificación de autorización de los servidores de Synapsis.
                            </div>
                            <!--<div class="text-content-bigger">Descarga un ejemplo de integración completo al botón de
                                pago móvil iOS, <a href="https://github.com/synapsolutions/shop-ios">visita nuestro link
                                    en git.</a>
                            </div>-->
                            <div class="text-content">Descarga un ejemplo de integración completo al btoón de pagos móvil iOS:<br>
                                Para pagos con tarjetas <a
                                    href="https://github.com/synapsolutions/shop-ios">visita nuestro link
                                    en git.</a><br>
                                Para pagos en bancos <a
                                    href="https://github.com/synapsolutions/shop-ios-bank">visita nuestro link
                                    en git.</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12">
                    <div class="img-container">
                        <img class="img-iphone" src="assets/img/ios-button.png">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12">
                    <div class="text-container">
                        <div class="paragraph">
                            <h2>Instalación</h2>
                            <div class="text-content">La librería iOS es full nativa, permitiendo la integración de
                                cualquier app en el lenguaje de programación swift.
                            </div>
                            <div class="text-content">1. Descargue y descomprima el archivo SynapPay.framework.zip. <br>
                                Para ambiente de integración <a
                                    href="https://sbx.button.pay.synapsolutions.com/ios/latest/sandbox/SynapPay.framework.zip">descárguelo
                                    aquí</a><br>
                                Para el ambiente de producción y subir al "AppStore de Apple" <a
                                    href="https://sbx.button.pay.synapsolutions.com/ios/latest/production/SynapPay.framework.zip">descárguelo
                                    aquí</a>
                            </div>
                            <div class="text-content">2. Arrastre el directorio completo "SynapPay.framework" al
                                proyecto en Xcode y asegúrese de seleccionar "Copiar elementos si es necesario".
                            </div>
                            <div class="row">
                                <div class="col-xs-12 col-md-6">
                                    <div class="img-container">
                                        <img class="img-screen" src="assets/img/ios-button-02-01.png">
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <div class="img-container">
                                        <img class="img-screen" src="assets/img/ios-button-02-02.png">
                                    </div>
                                </div>
                            </div>
                            <div class="text-content">3. Asegurese que la libreria se encuentra en la sección
                                "Frameworks, Libraries and Embedded Content" de la pestaña de configuración "General"
                                del proyecto.
                            </div>
                            <div class="row">
                                <div class="col-xs-12 col-md-6">
                                    <div class="img-container">
                                        <img class="img-screen" src="assets/img/ios-button-02-03.png">
                                    </div>
                                </div>
                            </div>
                            <aside class="note"><b>Nota:</b> Estos pasos se deben repetir cuando se actualice la
                                librería
                                de pago a su última versión.</aside>
                        </div>
                        <div class="paragraph">
                            <h2>Configuración</h2>

                            <div class="tab-wrap">
                                <input type="radio" name="tabs" id="tab1" checked>
                                <div class="tab-label-content" id="tab1-content">
                                    <label for="tab1">Pago con Tarjeta</label>
                                    <div class="tab-content"><app-ios-button-cards></app-ios-button-cards></div>
                                </div>
                                <input type="radio" name="tabs" id="tab2">
                                <div class="tab-label-content" id="tab2-content">
                                    <label for="tab2">Pago en Banco</label>
                                    <div class="tab-content"><app-ios-button-banks></app-ios-button-banks></div>
                                </div>
                                <div class="slide"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>