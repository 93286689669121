<div class="row center-xs">
    <div class="col-xs-12 col-sm-11 col-md-9">
        <div class="row">
            <div class="col-xs-12">
                <div class="text-container">
                    <div class="paragraph">
                        <h1>AUTENTICACIÓN</h1>
                        <div class="text-content-bigger">La API Synapsis utiliza un APIKey y un SignatureKey para
                            autenticar solicitudes y garantizar la integridad del mensaje.
                        </div>
                        <div class="text-content-bigger">
                            El APIKey y SignatureKey pueden ser visualizadas en la plataforma de gestión de Synapsis.
                        </div>
                        <div class="text-content-bigger">
                            El APIKey y SignatureKey tienen muchos privilegios, ¡asegúrese de mantenerlas seguras! No
                            las comparta en áreas de acceso público como GitHub, código del lado del cliente, etc.
                        </div>
                        <div class="text-content-bigger">
                            La autenticación a la API se realiza enviando el APIKey y Signature en el header de la
                            petición, a continuación se detallan los campos del header que se envían para la
                            autenticación y para garantizar la inegridad del mensaje:
                        </div>
                        <div class="text-content-bigger">
                            1. Identifier: ApiKey del comercio
                        </div>
                        <div class="text-content-bigger">
                            2. SignedElement: Campos concatenados que serán firmados, por ejemplo: order.number, order.currency.code, order.amount
                        </div>
                        <div class="text-content-bigger">
                            3. Signature: Campos concatenados que serán cifrados con SHA512, considerando los campos Identifier, SignetElement y SignatureKey
                        </div>
                        <div class="text-content-bigger">
                            Todas las solicitudes de API deben hacerse a través de HTTPS. Las llamadas realizadas a
                            través de HTTP simple fallarán. Las solicitudes de API sin autenticación también fallarán.
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-12">
                <div class="img-container">
                    <img class="img-bigger" src="assets/img/security.png">
                </div>
            </div>
        </div>
    </div>
</div>