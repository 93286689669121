import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-web-button',
  templateUrl: './web-button.component.html',
  styleUrls: ['./web-button.component.scss'],
})
export class WebButtonComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }

}
