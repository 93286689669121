import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MaterialModule } from './material.module';
import { FormsModule } from '@angular/forms';
import { LayoutModule } from '@angular/cdk/layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OverlayModule } from '@angular/cdk/overlay';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './content/pages/header/header.component';
import { SidenavComponent } from './content/pages/sidenav/sidenav.component';
import { MenuItemComponent } from './content/pages/menu-item/menu-item.component';

import { MainComponent } from './content/pages/main/main.component';
import { PaymentsComponent } from './content/pages/payments/payments.component';
import { WebButtonComponent } from './content/pages/conexion/web-button/web-button.component';
import { IosButtonComponent } from './content/pages/conexion/ios-button/ios-button.component';
import { IosButtonCardsComponent } from './content/pages/conexion/ios-button-cards/ios-button-cards.component';
import { IosButtonBanksComponent } from './content/pages/conexion/ios-button-banks/ios-button-banks.component';
import { AndroidButtonComponent } from './content/pages/conexion/android-button/android-button.component';
import { AndroidButtonCardsComponent } from './content/pages/conexion/android-button-cards/android-button-cards.component';
import { AndroidButtonBanksComponent } from './content/pages/conexion/android-button-banks/android-button-banks.component';
import { SecurityComponent } from './content/pages/security/security.component';
import { BotComponent } from './content/pages/bot/bot.component';
import { LinkComponent } from './content/pages/link/link.component';
import { BankComponent } from './content/pages/bank/bank.component';
import { ApiAuthenticationComponent } from './content/pages/api/api-authentication/api-authentication.component';
import { ApiOrderComponent } from './content/pages/api/api-order/api-order.component';
import { ApiPaymentCardComponent } from './content/pages/api/api-payment-card/api-payment-card.component';
import { ApiPaymentBankComponent } from './content/pages/api/api-payment-bank/api-payment-bank.component';
import { ApiWebhookComponent } from './content/pages/api/api-webhook/api-webhook.component';
import { ControlIntroductionComponent } from './content/pages/control/control-introduction/control-introduction.component';
import { ControlDashboardComponent } from './content/pages/control/control-dashboard/control-dashboard.component';
import { ControlOrderComponent } from './content/pages/control/control-order/control-order.component';
import { ControlPaymentsComponent } from './content/pages/control/control-payments/control-payments.component';

import { ButtonComponent } from './content/pages/dialog/button/button.component';
import { NgGistModule } from 'ng-gist'

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidenavComponent,
    MenuItemComponent,

    MainComponent,
    PaymentsComponent,
    WebButtonComponent,
    IosButtonComponent,
    IosButtonCardsComponent,
    IosButtonBanksComponent,
    AndroidButtonComponent,
    AndroidButtonCardsComponent,
    AndroidButtonBanksComponent,
    SecurityComponent,
    BotComponent,
    LinkComponent,
    BankComponent,
    ApiAuthenticationComponent,
    ApiOrderComponent,
    ApiPaymentCardComponent,
    ApiPaymentBankComponent,
    ApiWebhookComponent,
    ControlIntroductionComponent,
    ControlDashboardComponent,
    ControlOrderComponent,
    ControlPaymentsComponent,

    ButtonComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    BrowserAnimationsModule,
    FormsModule,
    LayoutModule,
    OverlayModule,
    NgGistModule
  ],
  providers: [],
  bootstrap: [AppComponent],
  entryComponents: [
    ButtonComponent
  ]
})
export class AppModule { }
