import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-control-payments',
  templateUrl: './control-payments.component.html',
  styleUrls: ['./control-payments.component.scss']
})
export class ControlPaymentsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
