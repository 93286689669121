import { Component, OnInit } from '@angular/core';

declare const SwaggerUIBundle: any;

@Component({
  selector: 'app-api-webhook',
  templateUrl: './api-webhook.component.html',
  styleUrls: ['./api-webhook.component.scss']
})
export class ApiWebhookComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    const ui = SwaggerUIBundle({
      dom_id: '#webhook-swagger-ui',
      layout: 'BaseLayout',
      presets: [
        SwaggerUIBundle.presets.apis,
        SwaggerUIBundle.SwaggerUIStandalonePreset
      ],
      url: '/assets/json/webhook-swagger.json',
      // url: '/assets/json/test.json',
    });
  }

}
