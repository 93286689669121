<div class="text-content">1. Importe la librería de pago iOS.
</div>
<div class="code-content-xs">
    <!--<iframe src="https://pastebin.com/embed_iframe/sDvDythz"
        style="border:none;width:100%;height:75px;padding-bottom:10px;"></iframe>-->
    <ng-gist userName="synapdevelopment" gistId="529603e8c639ee860fc5313fb8a84a00"
        fileName="merchant-ios-viewcontroller-imports.swift"></ng-gist>
</div>
<div class="text-content">2. Agregue la variable que permite el acceso a las funcionalidades
    de la librería de pago en el viewcontroller.
</div>
<div class="code-content-xs">
    <!--<iframe src="https://pastebin.com/embed_iframe/RyBx8EcE"
        style="border:none;width:100%;height:75px;padding-bottom:10px;"></iframe>-->
    <ng-gist userName="synapdevelopment" gistId="58af02632d998c59341744631b1de860"
        fileName="merchant-ios-viewcontroller-properties-paymentwidget.swift"></ng-gist>
</div>
<div class="text-content">3. Agregue el control "View" como contenedor del formulario de
    pago y asócielo en el viewcontroller.
</div>
<div class="code-content-xs">
    <!--<iframe src="https://pastebin.com/embed_iframe/EkdyCpdB"
        style="border:none;width:100%;height:75px;padding-bottom:10px;"></iframe>-->
    <ng-gist userName="synapdevelopment" gistId="614652824bb26ade39d86025356f7b67"
        fileName="merchant-ios-viewcontroller-properties-synapform.swift"></ng-gist>
</div>
<aside class="note"><b>Nota:</b> Las dimensiones del contenedor "synapForm" deben ser de 320px de
    ancho por 220px de alto.
    <br> Las dimensiones del contenedor "SynapWebView" deben ser de toda la pantalla (ancho y alto).
</aside>
<div class="text-content">4. Agregue el control "Button" como botón que solicitará el pago y
    asócielo en el viewcontroller.
</div>
<div class="code-content-xs">
    <!--<iframe src="https://pastebin.com/embed_iframe/sJiLKMHs"
        style="border:none;width:100%;height:75px;padding-bottom:10px;"></iframe>-->
    <ng-gist userName="synapdevelopment" gistId="5d85b23219bf78a04b309e280641599e"
        fileName="merchant-ios-viewcontroller-properties-synapbutton.swift"></ng-gist>
</div>
<div class="text-content">5. Oculte inicialmente el contenedor del formulario de pago y el
    botón de pago.
</div>
<div class="code-content-md">
    <!--<iframe src="https://pastebin.com/embed_iframe/rUjxVy43"
        style="border:none;width:100%;height:250px;padding-bottom:10px;"></iframe>-->
    <ng-gist userName="synapdevelopment" gistId="c02b68f570c6e2d35f6e55d67fe963d2"
        fileName="merchant-ios-viewcontroller-func-viewdidload.swift"></ng-gist>
</div>
<div class="text-content">6. Inicialice y configure el formulario de pago (visualmente, el
    ambiente de procesamiento de pago y la gestión visual de la respuesta).
</div>
<div class="code-content">
    <!--<iframe src="https://pastebin.com/embed_iframe/gjnSgmfL"
        style="border:none;width:100%;height:600px;padding-bottom:10px;"></iframe>-->
    <ng-gist userName="synapdevelopment" gistId="8a6e34e3568ccb87ac0fe97760878240"
        fileName="merchant-ios-viewcontroller-func-startpayment.swift"></ng-gist>
</div>
<div class="text-content">7. Setee los datos de la orden o pedido.
</div>
<div class="code-content">
    <!--<iframe src="https://pastebin.com/embed_iframe/RPhQbKXX"
        style="border:none;width:100%;height:600px;padding-bottom:10px;"></iframe>-->
    <ng-gist userName="synapdevelopment" gistId="51fdde8a27bcc8551ef15a011fd315fa"
        fileName="merchant-ios-viewcontroller-func-buildtransaction.swift"></ng-gist>
</div>
<div class="text-content">8. Setee los datos de autenticación. Para más información diríjase
    a la sección de <a routerLink="/security">"Seguridad".</a>
</div>
<div class="code-content">
    <!--<iframe src="https://pastebin.com/embed_iframe/SN6XJv4i"
        style="border:none;width:100%;height:400px;padding-bottom:10px;"></iframe>-->
    <ng-gist userName="synapdevelopment" gistId="1783cb6e073177d8c2ee9381fa685b88"
        fileName="merchant-ios-viewcontroller-func-buildauthentication.swift"></ng-gist>
</div>
<aside class="note"><b>Nota:</b> El APIKey y SignatureKey están disponibles en la plataforma
    de gestión de Synapsis (Synap - Control)
</aside>
<div class="text-content">9. Asocie la acción del botón de pago "synapButton" a la función
    de pago del widget.
</div>
<div class="code-content-sm">
    <!--<iframe src="https://pastebin.com/embed_iframe/36xs0Uq3"
        style="border:none;width:100%;height:100px;padding-bottom:10px;"></iframe>-->
    <ng-gist userName="synapdevelopment" gistId="ec9f0f6cd2f184e1df86dfc0b315fa01"
        fileName="merchant-ios-viewcontroller-func-synapactionpay.swift"></ng-gist>
</div>
<div class="text-content">10. Las siguientes funciones son utilitarias y deben ser
    utilizadas solo como "referencia".
</div>
<div class="code-content">
    <!--<iframe src="https://pastebin.com/embed_iframe/nAiM2ZFy"
        style="border:none;width:100%;height:600px;padding-bottom:10px;"></iframe>-->
    <ng-gist userName="synapdevelopment" gistId="6726064da619671a19759946e4cb573b"
        fileName="merchant-ios-viewcontroller-func-utilities.swift"></ng-gist>
</div>