import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ButtonComponent } from '../dialog/button/button.component';

export interface Cards {
	brand?: string;
	number?: string;
	date?: string;
	cvv2?: string;
	type?: string;
}

const ELEMENT_DATA: Cards[] = [
	{ brand: 'Visa', number: '4242 4242 4242 4242', date: '12/24', cvv2: '123', type: 'Autorizado' },
	{ brand: 'Mastercard', number: '5555 5555 5555 4444', date: '12/24', cvv2: '123', type: 'Autorizado' },
	{ brand: 'Amex', number: '3782 822463 10005', date: '12/24', cvv2: '1234', type: 'Autorizado' },
	{ brand: 'Diners', number: '3654 8485 6815 51', date: '12/24', cvv2: '123', type: 'Autorizado' },
	{ brand: 'Visa', number: '4859 5100 0000 0077', date: '12/24', cvv2: '123', type: 'Denegado' },
];

let TRANSACTION: any = {
	order: {
		// number: (new Date()).getTime() + "",
		number: null,
		country: {
			code: "PER"
		},
		currency: {
			code: "PEN"
		},
		amount: 1.00,
		customer: {
			name: "Julio",
			lastName: "Molina",
			phone: "999888777",
			email: "julio.molina@outlook.com", //"review@review.com",
			document: {
				type: "DNI",
				number: "10203040"
			},
			address: {
				country: "PER",
				levels: []
			}
		},
		products: [
			{
				name: "Pago desde web",
				// imageUrl: "https://www.cdconceptos.com/wp-content/uploads/2019/04/cd-conceptos-acreditaciones-hastqb-2.jpg-1.png",
				// quantity: "1",
				// unitAmount: "1.00",
				// amount: "1.00"
			}
		],
		orderType: {
			code: "ORDER"
		},
		targetType: {
			code: "SINGLE"
		}
	},
	settings: {
		brands: ['VISA', 'MSCD', 'AMEX', 'DINC'],
		language: "es_PE",
		businessService: "WEB"
	}
}

@Component({
	selector: 'app-payments',
	templateUrl: './payments.component.html',
	styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent implements OnInit {
	displayedColumns: string[] = ['brand', 'number', 'date', 'cvv2', 'type'];
	dataSource = ELEMENT_DATA;

	constructor(public dialog: MatDialog) { }

	ngOnInit(): void {
	}

	openDialog() {
		TRANSACTION.order.number = (new Date()).getTime() + "";
		const dialogRef = this.dialog.open(ButtonComponent, {
			width: '375px', maxWidth: 'unset',
			height: '550px', maxHeight: 'unset',
			data: { title: 'Ingresa tu tarjeta', transaction: TRANSACTION, body: 'DASHBOARD.DIALOG.MESSAGE_REVERSE' }
		});

		dialogRef.afterClosed().subscribe(result => {
			console.log(result);
		})
	}
}