import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map, shareReplay } from 'rxjs/operators';
import { MatIconRegistry } from '@angular/material/icon';
import { MenuItem } from 'src/app/models/menu-item.model';
import { MatSidenavContainer } from '@angular/material/sidenav';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  viewProviders: [MatIconRegistry],
})
export class SidenavComponent implements OnInit {  
  @ViewChild('sidenavContainer') sidenavContainer: MatSidenavContainer;

  menuList: MenuItem[] = [{
    label: 'Información General',
    enabled: true,
    icon: null,
    route: 'main',
    children: null,
  }, {
    label: 'Pagos Inteligentes',
    enabled: true,
    icon: null,
    route: 'payments',
    children: null,
  }, {
    label: "Librerías de Pago",
    enabled: true,
    icon: null,
    route: null,
    children: [{
      label: 'Librería Web',
      enabled: true,
      icon: null,
      route: 'web-button',
      children: null,
    },{
      label: 'Librería Móvil - iOS',
      enabled: true,
      icon: null,
      route: 'ios-button',
      children: null
    }, {
      label: 'Librería Móvil - Android',
      enabled: true,
      icon: null,
      route: 'android-button',
      children: null
    }],
  }, {
    label: 'Seguridad',
    enabled: true,
    icon: null,
    route: 'security',
    children: null,
  }, {
    label: 'Pago por Bot',
    enabled: true,
    icon: null,
    route: 'bot',
    children: null,
  }, {
    label: 'Pagos por Link',
    enabled: true,
    icon: null,
    route: 'link',
    children: null,
  }, {
    label: 'Pagos en Banco',
    enabled: true,
    icon: null,
    route: 'bank',
    children: null,
  }, {label: 'Referencias de API',
    enabled: true,
    icon: null,
    route: null,
    children: [{
      label: 'Autenticación',
      enabled: true,
      icon: null,
      route: 'api-authentication',
      children: null
    }, {
      label: 'Orden',
      enabled: true,
      icon: null,
      route: 'api-order',
      children: null
    }, {
      label: 'Pagos con Tarjeta',
      enabled: true,
      icon: null,
      route: 'api-payment-card',
      children: null
    }, {
      label: 'Pagos en Banco',
      enabled: true,
      icon: null,
      route: 'api-payment-bank',
      children: null
    }, {
      label: 'Notificaciones',
      enabled: true,
      icon: null,
      route: 'api-notification',
      children: null
    }, {
      label: 'Webhooks',
      enabled: true,
      icon: null,
      route: 'api-webhook',
      children: null
    }]
  }/*, {
    label: 'Gestión y Control',
    enabled: true,
    icon: null,
    route: null,
    children: [{
      label: 'Introducción',
      enabled: true,
      icon: null,
      route: 'control-introduction',
      children: null
    }, {
      label: 'Tablero de Control',
      enabled: true,
      icon: null,
      route: 'control-dashboard',
      children: null
    }, {
      label: 'Ordenes',
      enabled: true,
      icon: null,
      route: 'control-order',
      children: null
    }, {
      label: 'Pagos',
      enabled: true,
      icon: null,
      route: 'control-payment',
      children: null
    }]
  }*/]

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(private breakpointObserver: BreakpointObserver) { }

  ngOnInit() { }

  onActivate(e){
    this.sidenavContainer.scrollable.scrollTo({ left: 0, top: 0 });
  }

}
