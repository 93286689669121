import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-android-button-banks',
  templateUrl: './android-button-banks.component.html',
  styleUrls: ['./android-button-banks.component.scss']
})
export class AndroidButtonBanksComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
