<div class="text-content">1. Importe la librería de pago Android.
</div>
<div class="code-content-xs">
    <ng-gist userName="synapdevelopment" gistId="3561cead83fef301c17e5bd24da27efb"
        fileName="android-banks-mainactivity-imports.java"></ng-gist>
</div>
<div class="text-content">2. Agregue la variable que permite el acceso a las funcionalidades
    de la librería de pago en el mainactivity.
</div>
<div class="code-content-xs">
    <ng-gist userName="synapdevelopment" gistId="445b207f87fb488820c3aa292a51adc7"
        fileName="android-banks-mainactivity-properties-paymentwidget.java"></ng-gist>
</div>
<div class="text-content">3. Agregue el control "FrameLayout" como contenedor del formulario
    de pago y asócielo en el mainactivity.
</div>
<div class="code-content-xs">
    <ng-gist userName="synapdevelopment" gistId="e1668d9bfd5e0a4c1ea6283a6ccd6816"
        fileName="android-banks-mainactivity-properties-synapform.java"></ng-gist>
</div>
<aside class="note"><b>Nota:</b> Las dimensiones del contenedor "synapForm" deben ser de 320dp de
    ancho por 500dp de alto.
    <br>Las dimensiones del contenedor "SynapWebView" deben ser de toda la pantalla (ancho y alto).
</aside>

<div class="text-content">4. Oculte inicialmente el contenedor del formulario de pago.
</div>
<div class="code-content">
    <ng-gist userName="synapdevelopment" gistId="2957a562ea166f9f09f26734e186d470"
        fileName="android-banks-mainactivity-func-oncreate.java"></ng-gist>
</div>
<div class="text-content">5. Inicialice y configure el formulario de pago (visualmente, el
    ambiente de procesamiento de pago y la gestión visual de la respuesta).
</div>
<div class="code-content">
    <ng-gist userName="synapdevelopment" gistId="6149aa81f1669f0695416d5e5d6a3fdb"
        fileName="android-banks-mainactivity-func-startpayment.java"></ng-gist>
</div>
<div class="text-content">6. Setee los datos de la orden o pedido.
</div>
<div class="code-content">
    <ng-gist userName="synapdevelopment" gistId="62f12f0c92cec2beeebadba0e4ecfdf9"
        fileName="android-banks-mainactivity-func-buildtransaction.java"></ng-gist>
</div>
<div class="text-content">7. Setee los datos de autenticación. Para más información diríjase
    a la sección de <a routerLink="/security">"Seguridad".</a>
</div>
<div class="code-content">
    <ng-gist userName="synapdevelopment" gistId="0517d567a7fa35950f17c1f933804ad4"
        fileName="android-banks-mainactivity-func-buildauthenticator.java"></ng-gist>
</div>
<aside class="note"><b>Nota:</b> El APIKey y SignatureKey están disponibles en la plataforma
    de gestión de Synapsis (Synap - Control)
</aside>
<div class="text-content">8. Las siguientes funciones son utilitarias y deben ser
    utilizadas solo como "referencia".
</div>
<div class="code-content">
    <ng-gist userName="synapdevelopment" gistId="ee61046f79def818519b4e3a1d62b752"
        fileName="android-banks-mainactivity-func-utilities.java"></ng-gist>
</div>