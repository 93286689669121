import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ios-button-cards',
  templateUrl: './ios-button-cards.component.html',
  styleUrls: ['./ios-button-cards.component.scss']
})
export class IosButtonCardsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
