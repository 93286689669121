import { Component, OnInit } from '@angular/core';

declare const SwaggerUIBundle: any;

@Component({
  selector: 'app-api-payment-bank',
  templateUrl: './api-payment-bank.component.html',
  styleUrls: ['./api-payment-bank.component.scss']
})
export class ApiPaymentBankComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    const ui = SwaggerUIBundle({
      dom_id: '#payment-bank-swagger-ui',
      layout: 'BaseLayout',
      presets: [
        SwaggerUIBundle.presets.apis,
        SwaggerUIBundle.SwaggerUIStandalonePreset
      ],
      //url: 'https://petstore.swagger.io/v2/swagger.json',
      url: '/assets/json/payment-bank-swagger.json',
      //docExpansion: 'none',
      //operationsSorter: 'alpha'
    });
  }

}
