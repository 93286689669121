<div class="page-container">
    <div class="row center-xs">
        <div class="col-xs-12 col-sm-11 col-md-9">
            <div class="row">
                <div class="col-xs-12">
                    <div class="text-container">
                        <div class="paragraph">
                            <h1>BOTON ANDROID</h1>
                            <div class="text-content-bigger">El comercio puede conectarse con nuestras librerías móviles
                                para Android en menos de 2 horas.
                                Las librerías móviles están creadas en lenguajes nativos, permitiendo la integración
                                desde cualquier app móvil Android.
                            </div>
                            <div class="text-content-bigger">El formulario de pago se crea dinámicamente, haciendo
                                segura la captura y el envío de los datos de tarjeta.
                                El comercio decide cómo y dónde mostrar el formulario de pago, permitiendo continuar con
                                la experiencia del cliente en el app.
                            </div>
                            <div class="text-content-bigger">El pago es solicitado al procesador de pagos y la respuesta
                                es mostrada al cliente sin errores y sin demoras.
                                El comercio recibe la notificación de autorización de los servidores de Synapsis.
                            </div>
                            <!--<div class="text-content-bigger">Descarga un ejemplo de integración completo al botón de
                                pago móvil Android, <a href="https://github.com/synapsolutions/shop-android">visita
                                    nuestro link en git.</a>
                            </div>-->
                            <div class="text-content">Descarga un ejemplo de integración completo al btoón de pagos móvil iOS:<br>
                                Para pagos con tarjetas <a
                                    href="https://github.com/synapsolutions/shop-android">visita nuestro link
                                    en git.</a><br>
                                Para pagos en bancos <a
                                    href="https://github.com/synapsolutions/shop-android-bank">visita nuestro link
                                    en git.</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12">
                    <div class="img-container">
                        <img class="img-iphone" src="assets/img/android-button.png">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12">
                    <div class="text-container">
                        <div class="paragraph">
                            <h2>Instalación</h2>
                            <div class="text-content">La librería Android es full nativa, permitiendo la integración de
                                cualquier app en el lenguaje de programación java.
                            </div>
                            <div class="text-content">1. Descargue el archivo SynapPay-release.aar. <br>
                                Para los ambientes de integración, producción y para subir "PlayStore de Google" <a
                                    href="https://sbx.button.pay.synapsolutions.com/android/latest/SynapPay-release.aar">descárguelo
                                    aquí</a>
                            </div>
                            <div class="text-content">2. Ingrese a la opción de configuración de módulos y agregue el
                                módulo a su proyecto en Android Studio.
                            </div>
                            <div class="row">
                                <div class="col-xs-12 col-md-6">
                                    <div class="img-container">
                                        <img class="img-screen" src="assets/img/android-button-02-01.png">
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <div class="img-container">
                                        <img class="img-screen" src="assets/img/android-button-02-02.png">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xs-12 col-md-6">
                                    <div class="img-container">
                                        <img class="img-screen" src="assets/img/android-button-02-03.png">
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <div class="img-container">
                                        <img class="img-screen" src="assets/img/android-button-02-04.png">
                                    </div>
                                </div>
                            </div>
                            <aside class="note"><b>Nota:</b> Al finalizar estos pasos, debe actualizar y presionar OK en
                                la pantalla de módulo.</aside>
                            <div class="text-content">3. Agregue la dependencia a la aplicación en la opción Dependencia
                                de Módulo, luego seleccione el check del módulo agregado SynapPay.
                            </div>
                            <div class="row">
                                <div class="col-xs-12 col-md-6">
                                    <div class="img-container">
                                        <img class="img-screen" src="assets/img/android-button-03-01.png">
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <div class="img-container">
                                        <img class="img-screen-small" src="assets/img/android-button-03-02.png">
                                    </div>
                                </div>
                            </div>
                            <aside class="note"><b>Nota:</b> Al finalizar estos pasos, debe actualizar y presionar OK en
                                la pantalla de módulo.</aside>


                            <div class="text-content">4. Agregue las dependencias en proyecto en el archivo
                                "build.gradle" de la aplicación.
                            </div>
                            <div class="code-content-sm">
                                <!--<iframe src="https://pastebin.com/embed_iframe/W3QWYqS9"
                                    style="border:none;width:100%;height:125px;padding-bottom:10px;"></iframe>-->
                                <ng-gist userName="synapdevelopment" gistId="56acd12c6566f04b7f6426882f60fa18"
                                    fileName="merchant-android-build.gradle"></ng-gist>
                            </div>

                        </div>
                        <div class="paragraph">
                            <h2>Configuración</h2>

                            <div class="tab-wrap">
                                <input type="radio" name="tabs" id="tab1" checked>
                                <div class="tab-label-content" id="tab1-content">
                                    <label for="tab1">Pago con Tarjeta</label>
                                    <div class="tab-content"><app-android-button-cards></app-android-button-cards></div>
                                </div>
                                <input type="radio" name="tabs" id="tab2">
                                <div class="tab-label-content" id="tab2-content">
                                    <label for="tab2">Pago en Banco</label>
                                    <div class="tab-content"><app-android-button-banks></app-android-button-banks></div>
                                </div>
                                <div class="slide"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>