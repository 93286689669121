<div class="row center-xs">
    <div class="col-xs-12 col-sm-11 col-md-9">
        <div class="row">
            <div class="col-xs-12">
                <div class="text-container">
                    <div class="paragraph">
                        <h1>PAGOS CON LINK</h1>
                        <div class="text-content-bigger">Empieza a vender y/o cobrar sin necesidad de una tienda web o
                            móvil.
                        </div>
                        <div class="text-content-bigger">
                            Crea links de pago seguro y envíalos por redes sociales, email, sms o cualquier otro medio
                            que acepte envio de Url.
                        </div>
                        <div class="text-content-bigger">
                            Si tu empresa emite recibos, puedes incluir un link de pago seguro en tu recibo digital para
                            que tus clientes lo paguen en un solo paso.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12 col-sm-6">
                <div class="text-container">
                    <div class="paragraph">
                        <h2>CREACIÓN DE LA ORDEN</h2>
                        <div class="text-content">El cliente y el comercio cierran la venta y se crea la “Orden".
                        </div>
                        <div class="text-content">El comercio envía un link de pago al cliente por redes sociales,
                            email, sms o cualquier otro medio que acepte envío de Url.
                        </div>
                        <div class="text-content">El linkde de pago tiene las siguientes Url:
                            <br><br>
                            <div class="bold">Sandbox:</div>
                            https://sbx.link.pay.synapsolutions.com/?identifier=[identifier]
                            <br><br>
                            <div class="bold">Producción:</div>
                            https://link.pay.synapsolutions.com/?identifier=[identifier]
                        </div>
                        <div class="text-content">El campo [identifier] es el identificador único de la orden generado
                            por Synapsis.
                        </div>
                    </div>
                    <div class="paragraph">
                        <h2>PAGO EN ZONA SEGURA</h2>
                        <div class="text-content">El cliente abre el link de pago y visualiza los datos de compra, luego
                            de aceptar los términos y condiciones, ingresa los datos de tarjeta de forma segura.
                        </div>
                    </div>
                    <div class="paragraph">
                        <h2>ENVÍO DE NOTIFICACIONES</h2>
                        <div class="text-content">El pago es solicitado al procesador (Niubiz) y la respuesta es
                            mostrada al cliente sin errores y sin demoras.
                        </div>
                        <div class="text-content">El comercio recibe la notificación de autorización de los servidores
                            de Synapsis.
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-sm-6">
                <div class="img-container">
                    <img class="img-iphone" src="assets/img/link.png">
                </div>
            </div>
        </div>
    </div>
</div>