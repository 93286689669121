<div class="text-content">1. Importe la librería de pago iOS.
</div>
<div class="code-content-xs">
    <ng-gist userName="synapdevelopment" gistId="1cc253d3e56a119fffb7c6e1b915943c"
        fileName="ios-banks-viewcontroller-imports.swift"></ng-gist>
</div>
<div class="text-content">2. Agregue la variable que permite el acceso a las funcionalidades
    de la librería de pago en el viewcontroller.
</div>
<div class="code-content-xs">
    <ng-gist userName="synapdevelopment" gistId="c18fc9df2d6d4082c1bbc42454377cfc"
        fileName="ios-banks-viewcontroller-properties-paymentwidget.swift"></ng-gist>
</div>

<div class="text-content">3. Agregue el control "View" como contenedor del formulario de
    pago y asócielo en el viewcontroller.
</div>
<div class="code-content-xs">
    <ng-gist userName="synapdevelopment" gistId="ef96178447aeb128be913110eb5d1f8e"
        fileName="ios-banks-viewcontroller-properties-synapform.swift"></ng-gist>
</div>
<aside class="note"><b>Nota:</b> Las dimensiones del contenedor "synapForm" deben ser de 320px de
    ancho por 500px de alto.
    <br> Las dimensiones del contenedor "SynapWebView" deben ser de toda la pantalla (ancho y alto).
</aside>
<!--<div class="text-content">4. Agregue el control "Button" como botón que solicitará el pago y
    asócielo en el viewcontroller.
</div>
<div class="code-content-xs">
    <ng-gist userName="synapdevelopment" gistId="016f6445228f14c830d05cd6beba13cf"
        fileName="ios-banks-viewcontroller-properties-synapbutton.swift"></ng-gist>
</div>-->

<div class="text-content">4. Oculte inicialmente el contenedor del formulario de pago.
</div>
<div class="code-content-md">
    <ng-gist userName="synapdevelopment" gistId="27a1f77d1c4949652c03e6a55d77ecb9"
        fileName="ios-banks-viewcontroller-func-viewdidload.swift"></ng-gist>
</div>
<div class="text-content">5. Inicialice y configure el formulario de pago (visualmente, el
    ambiente de procesamiento de pago y la gestión visual de la respuesta).
</div>
<div class="code-content">
    <ng-gist userName="synapdevelopment" gistId="12a452a584a33f250c1a72be89a01570"
        fileName="ios-banks-viewcontroller-func-startpayment.swift"></ng-gist>
</div>
<div class="text-content">6. Setee los datos de la orden o pedido.
</div>
<div class="code-content">
    <ng-gist userName="synapdevelopment" gistId="e9a4618d0b6a512664d3906ce2e3d215"
        fileName="ios-banks-viewcontroller-func-buildtransaction.swift"></ng-gist>
</div>

<div class="text-content">7. Setee los datos de autenticación. Para más información diríjase
    a la sección de <a routerLink="/security">"Seguridad".</a>
</div>
<div class="code-content">
    <ng-gist userName="synapdevelopment" gistId="d1703b9a0d8bb0dcaa4b55c544226ab1"
        fileName="ios-banks-viewcontroller-func-buildauthentication.swift"></ng-gist>
</div>
<aside class="note"><b>Nota:</b> El APIKey y SignatureKey están disponibles en la plataforma
    de gestión de Synapsis (Synap - Control)
</aside>

<!--
<div class="text-content">9. Asocie la acción del botón de pago "synapButton" a la función
    de pago del widget.
</div>
<div class="code-content-sm">
    <ng-gist userName="synapdevelopment" gistId="ec9f0f6cd2f184e1df86dfc0b315fa01"
        fileName="merchant-ios-viewcontroller-func-synapactionpay.swift"></ng-gist>
</div>
-->
<div class="text-content">8. Las siguientes funciones son utilitarias y deben ser
    utilizadas solo como "referencia".
</div>
<div class="code-content">
    <ng-gist userName="synapdevelopment" gistId="5e061ecfc0fcd38322a879f416d4c88e"
        fileName="ios-banks-viewcontroller-func-utilities.swift"></ng-gist>
</div>
