import { Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

declare const Synap: any;
declare const sha512: any;

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  transaction: any
  
  constructor(
    private cdr: ChangeDetectorRef,
    public dialogRef: MatDialogRef<ButtonComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.transaction = data.transaction;
      //this.startPayment();
    }

  ngOnInit(): void {
    this.startPayment();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  startPayment() {
    var transaction = this.transaction; //jsonTransaction.data;
    var authenticator = this.buildAuthentication();

    /*var settings = {
      "brands": TRANSACTION.settings.brands, //jsonTransaction.data.settings.brands, //['VISA', 'MSCD', 'AMEX', 'DINC'],
      "language": TRANSACTION.settings.language, //jsonTransaction.data.settings.language == null ? localeString : jsonTransaction.data.settings.language,
      "autogenerate": TRANSACTION.settings.autogenerate,
      "businessService": 'WEB', //jsonTransaction.data.settings.businessService,
    }
    orderMinimalData['settings'] = settings;*/

    const me = this;
    var form = new Synap.Form({
      authentication: authenticator,
      transaction: transaction,
      environment: 'sandbox', //environment.ID_ENVIRONMENT, 
      listeners: {
        afterPay: function (response) {
          me.generateResponse(response);
        }
      }
    });
    form.render('#cart-container');

    var buttonPayment = document.querySelector("div[synap-identifier='payment-button']");
    const amount = (Math.round(this.transaction.order.amount * 100) / 100).toFixed(2);
    //const innerText = this.transaction.order.orderType.code == 'SUBSCRIPTION' ? 'Suscribirme y Pagar' : this.transaction.order.orderType.code == 'BILL' ? 'Agregar mi Tarjeta y Pagar' : 'Pagar'
    buttonPayment.innerHTML = `Pagar S/ ${amount.toString()}`

    this.cdr.detectChanges();
  }

  generateResponse(response) {
    const data = {
      response: response,
      transaction: this.transaction
    }

    this.delay(3000).then(any => {
      //your task after delay.
      this.dialogRef.close();
    });
    //this.messageService.sendMessage('SET_RESPONSE', data);
  }

  async delay(ms: number) {
    // await new Promise(resolve => setTimeout(()=>resolve(), ms)).then(()=>console.log("fired"));
    await new Promise<void>(resolve => setTimeout(()=>resolve(), ms)).then(()=>console.log("fired"));
  }

  buildAuthentication() {
    const apiKey = 'ab254a10-ddc2-4d84-8f31-d3fab9d49520'; // '4d78b7b1-52cd-418b-8532-94cf0a1d514c'; //
   
    // La signatureKey y la función de generación de firma debe usarse e implementarse en el servidor del comercio utilizando la función criptográfica SHA-512
    // solo con propósito de demostrar la funcionalidad, se implementará en el ejemplo
    // (bajo ninguna circunstancia debe exponerse la signatureKey y la función de firma desde la aplicación porque compromete la seguridad)
    const signatureKey = 'eDpehY%YPYgsoludCSZhu*WLdmKBWfAo'; // 'x#lE6WT*4duyMODG*nIaD#Ma84qeS$ra'; //
    const signature = this.generateSignature(apiKey, signatureKey);
   
    // Seteo de identificador del comercio (apiKey) y de firma, que permite verificar la integridad de la transacción
    var authenticator = {
      identifier: apiKey,
      signature: signature,
    }
   
    return authenticator;
  }

  generateSignature(apiKey, signatureKey) {
    const orderNumber = this.transaction.order.number;
    const currencyCode = this.transaction.order.currency.code;
    const amount = this.transaction.order.amount;
   
    const rawSignature =  apiKey + orderNumber + currencyCode + amount + signatureKey
    const signature = sha512(rawSignature);
    return signature;
  }
}
