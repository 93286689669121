<div class="row center-xs">
    <div class="col-xs-12 col-sm-11 col-md-9">
        <div class="row">
            <div class="col-xs-12">
                <div class="text-container">
                    <div class="paragraph">
                        <h1>PAGOS CON BOT</h1>
                        <div class="text-content-bigger">Incrementa las ventas y/o cobranzas telefónicas de tu comercio
                            sin solicitar al cliente que dicte sus datos de tarjeta; en su lugar el Bot (asistente
                            virtual) solicita el ingreso de estos datos sensibles a través del teclado telefónico de
                            forma segura.
                        </div>
                        <div class="text-content-bigger">
                            Se integra extremadamente fácil con cualquier central telefónica, solo debes poder unir al
                            Bot en la llamada con el cliente, y de inmediato empezará a interactuar solicitando el
                            ingreso de los datos de tarjeta a través del teclado telefónico.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12 col-sm-6">
                <div class="text-container">
                    <div class="paragraph">
                        <h2>CREACIÓN DE LA ORDEN</h2>
                        <div class="text-content">El cliente y el comercio cierran la venta y se crea la “Orden".
                        </div>
                        <div class="text-content">El comercio indica que pasará a una zona segura donde el asistente
                            virtual solicita el ingreso de los datos de tarjeta de forma segura por teclado telefónico.
                        </div>
                    </div>
                    <div class="paragraph">
                        <h2>PAGO EN ZONA SEGURA</h2>
                        <div class="text-content">El asistente virtual se presenta y solicita el ingreso del documento
                            de identidad, luego el asistente virtual indica al cliente que tiene un monto por pagar del
                            comercio y solicita los datos de tarjeta para el pago.
                        </div>
                    </div>
                    <div class="paragraph">
                        <h2>ENVÍO DE NOTIFICACIONES</h2>
                        <div class="text-content">El pago es solicitado al procesador (Niubiz) y la respuesta es
                            indicada al cliente por el asistente virtual, solicitando que no cuelgue la llamada para la
                            confirmación del servicio por el comercio.
                        </div>
                        <div class="text-content">El comercio recibe la notificación de autorización de los servidores
                            de Synapsis.
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-sm-6">
                <div class="img-container">
                    <img class="img-iphone" src="assets/img/bot.png">
                </div>
            </div>
        </div>
    </div>
</div>