import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { MenuItem } from 'src/app/models/menu-item.model';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Router, NavigationExtras, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({ transform: 'rotate(0deg)' })),
      state('expanded', style({ transform: 'rotate(180deg)' })),
      transition('expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ])
  ]
})
export class MenuItemComponent implements OnInit {
  @Input() menuItem: MenuItem;
  expanded: boolean;

  constructor(public router: Router) {
  }

  ngOnInit(): void {
  }

  onMenuSelected(menuItem: MenuItem) {
    if (!menuItem.children || !menuItem.children.length) {
      this.router.navigate([menuItem.route]);
      //this.navService.closeNav();

      //this.router.navigateByUrl('/link', { skipLocationChange: true }).then(() =>
        //this.router.navigate([menuItem.route]));

    }
    if (menuItem.children && menuItem.children.length) {
      this.expanded = !this.expanded;
    }
  }

}
