<div class="row center-xs">
    <div class="col-xs-12 col-sm-11 col-md-9">
        <div class="row">
            <div class="col-xs-12">
                <div class="text-container">
                    <div class="paragraph">
                        <h1>DOCUMENTACIÓN</h1>
                        <div class="text-content-bigger">Siguiendo esta guía que incluye ejemplos, podrás conectar tu comercio con Synapsis en tiempo record.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12 col-sm-6">
                <div class="text-container">
                    <div class="paragraph">
                        <h2>CONEXIÓN EXTREMADAMENTE FÁCIL</h2>
                        <div class="text-content">Hemos reducido la dificultad de integración al máximo, permitiendo la
                            conexión de comercios a cualquier canal en menos de 2 horas.
                        </div>
                    </div>
                    <div class="paragraph">
                        <h2>PAGO SEGURO Y FLEXIBLE</h2>
                        <div class="text-content">El formulario de pago se crea dinámicamente, haciendo segura la
                            captura y el envío de los datos de tarjeta.
                        </div>
                        <div class="text-content">El comercio decide cómo y dónde mostrar el formulario de pago,
                            permitiendo continuar con la experiencia del cliente en la tienda web.
                        </div>
                    </div>
                    <div class="paragraph">
                        <h2>ENVÍO DE NOTIFICACIONES</h2>
                        <div class="text-content">El pago es solicitado al procesador de tarjetas y la respuesta es
                            mostrada al cliente sin errores y sin demoras.</div>
                        <div class="text-content">El comercio recibe la notificación de autorización de los servidores
                            de Synapsis.
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-sm-6">
                <div class="img-container">
                    <img class="img-iphone" src="assets/img/web-button.png">
                </div>
            </div>
        </div>
    </div>
</div>