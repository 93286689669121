<!--(click)="onMenuSelected(menuItem)"--> <!--href="{{menuItem.route}}"-->
<a mat-list-item (click)="onMenuSelected(menuItem)" 
    [ngClass]="{'active': menuItem.route ? router.isActive(menuItem.route, true): false, 'expanded': expanded}"
    class="menu-list-item">
    <div class="menu-container">
        <div class="{{menuItem.children && menuItem.children.length ? 'menu-parent-text' : 'menu-text'}}">
            {{menuItem.label}}
        </div>
        <div class="icon-expanded">
            <span *ngIf="menuItem.children && menuItem.children.length">
                <mat-icon [@indicatorRotate]="expanded ? 'expanded': 'collapsed'">
                    expand_more
                </mat-icon>
            </span>
        </div>
    </div>
</a>
<div *ngIf="expanded" class="sub-menu-container">
    <app-menu-item *ngFor="let child of menuItem.children" [menuItem]="child"></app-menu-item>
    <div class="divider"></div>
</div>