import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ios-button-banks',
  templateUrl: './ios-button-banks.component.html',
  styleUrls: ['./ios-button-banks.component.scss']
})
export class IosButtonBanksComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
