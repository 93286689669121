<h1 mat-dialog-title>{{data.title}}</h1>
<!--<div mat-dialog-content>-->

    <div id="checkout-pay" class="synap-container">
        <div id="checkout-container" class="row synap-container">
            <div class="blank-slate">
                <div id="cart-container" class="synap-container" name="cart-container">
                    <!-- Aquí se visualizará el widget de pago -->
                </div>
            </div>
        </div>
    </div>

<!--</div>-->
<!--<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">No Thanks</button>
    <button mat-button [mat-dialog-close]=true cdkFocusInitial>Yes</button>-->
    <!--<div class="row center-lg">
        <div class="col-xs-12 col-sm-12 col-md-12 action-zone">
            <div>
                <button class="btn btn-primary btn-md" [mat-dialog-close]=true
                    cdkFocusInitial>{{'DASHBOARD.BUTTON.CONTINUE'}}</button>
                <button class="btn btn-default btn-md left-10"
                    (click)="onNoClick()">{{'DASHBOARD.BUTTON.CANCEL'}}</button>
            </div>
        </div>
    </div>
</div>-->