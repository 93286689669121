<div class="row center-xs main-container">
    <div class="col-xs-12 col-sm-11 col-md-9">
        <div class="row">
            <div class="col-xs-12">
                <div class="text-container">
                    <div class="paragraph">
                        <h1>PAGO EN BANCO Y AGENTES</h1>
                        <div class="text-content-bigger">Realizar pagos de compras en la banca por internet o en
                            agencias, agentes o bodegas de tu preferencia es sencillo gracias a Synapsis y a sus aliados
                            PagoEfectivo y KashIO.
                        </div>
                        <div class="text-content-bigger">El cliente pagador solo debe seguir estos 3 simples pasos:
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="col-xs-12">
                <div class="img-container">
                    <img class="img-bigger" src="assets/img/bank.png">
                </div>
            </div> -->

            <div class="col-xs-12">
                <mat-horizontal-stepper primary>
                    <mat-step label="Paso 1">
                        <div style="width: 100%; margin: auto; padding: 20px 0 20px 0;">
                            <div style="display: flex;">
                                <div style="width: 90%;">
                                    <p style="padding: 20px 0 20px 0;">Selecciona Pago en Banca por Internet o Agente
                                        (PagoEfectivo ó KashIO) </p>
                                    <img class="img-step1" src="assets/img/bank_01.png">
                                </div>
                                <div style="width: 10%; align-self: center;">
                                    <button matStepperNext mat-icon-button color="primary"
                                        aria-label="Example icon button with a home icon">
                                        <mat-icon>navigate_next</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </mat-step>
                    <mat-step label="Paso 2">
                        <div style="width: 100%; margin: auto; padding: 20px 0 20px 0;">
                            <div style="display: flex;">
                                <div style="width: 10%; align-self: center;">
                                    <button matStepperPrevious mat-icon-button color="primary"
                                        aria-label="Example icon button with a home icon">
                                        <mat-icon>navigate_before</mat-icon>
                                    </button>
                                </div>
                                <div style="width: 80%;">
                                    <p style="padding: 20px 0 20px 0;">Siga las instrucción de pago (PagoEfectivo o
                                        KashIO) </p>
                                    <div class="img-container-step2">
                                        <img class="img-step2" style="padding-bottom: 20px;" src="assets/img/PagoEfectivo_02.png">
                                        <img class="img-step2" src="assets/img/Kashio_02.png">
                                    </div>
                                </div>
                                <div style="width: 10%; align-self: center;">
                                    <button matStepperNext mat-icon-button color="primary"
                                        aria-label="Example icon button with a home icon">
                                        <mat-icon>navigate_next</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </mat-step>
                    <mat-step label="Paso 3">
                        <div style="width: 100%; margin: auto; padding: 20px 0 20px 0;">
                            <div style="display: flex;">
                                <div style="width: 10%; align-self: center;">
                                    <button matStepperPrevious mat-icon-button color="primary"
                                        aria-label="Example icon button with a home icon">
                                        <mat-icon>navigate_before</mat-icon>
                                    </button>
                                </div>
                                <div style="width: 90%;">
                                    <p style="padding: 20px 0 20px 0;">Paga desde el banco, agencia o agente de
                                        preferencia (PagoEfectivo o
                                        KashIO) </p>
                                    <div class="img-container-step3" style="display: flex;">
                                        <img style="width: 50%;" src="assets/img/PagoEfectivo_03.png">
                                        <img style="width: 50%;" src="assets/img/Kashio_03.png">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-step>
                </mat-horizontal-stepper>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12 col-sm-6">

                <!--<div class="col-xs-12">-->
                <div class="text-container">
                    <div class="paragraph">
                        <h2>CREACIÓN DEL CÓDIGO DE PAGO EN BANCO</h2>
                        <div class="text-content">El código de pago es generado por el API de Pago en Banco. Para más
                            información diríjase a la sección de <a routerLink="/api-payment">"API de Pago en
                                Banco"</a>.
                        </div>
                        <div class="text-content">Las instrucciones de pago pueden mostrarse en la misma web del
                            comercio (iframe) o redireccionando a la siguiente Url:
                            <br><br>
                            <div class="bold">Sandbox:</div>
                            https://sbx.link.pay.synapsolutions.com/?paymentMethod=BANK&identifier=[webReference]
                            <br><br>
                            <div class="bold">Producción:</div>
                            https://link.pay.synapsolutions.com/?paymentMethod=BANK&identifier=[webReference]
                        </div>
                        <div class="text-content">El campo [webReference] se encuentra en la respuesta del API de Pago
                            en Banco y el valor se encuentra en el campo
                            "result.processorResult.paymentCode.webReference"
                        </div>
                    </div>
                    <div class="paragraph">
                        <h2>PAGO EN BANCO</h2>
                        <div class="text-content">Una vez que el cliente pagador realiza el pago por banca móvil, banca
                            web, agencia o agente.
                        </div>
                        <div class="text-content">Esta información es recibida en linea por la plataforma de Synapsis.
                        </div>
                    </div>
                    <div class="paragraph">
                        <h2>ENVÍO DE NOTIFICACIONES</h2>
                        <div class="text-content">El comercio recibe la notificación de pago en linea desde la
                            plataforma de Synapsis.
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-sm-6">
                <div class="img-container">
                    <img class="img" src="assets/img/PAGOEFECTIVO.svg">
                    <img class="img" src="assets/img/KASHIO.svg">
                </div>
            </div>
        </div>
    </div>
</div>