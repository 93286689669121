<div class="page-container">
    <div class="row center-xs">
        <div class="col-xs-12 col-sm-11 col-md-9">
            <div class="row">
                <div class="col-xs-12">
                    <div class="text-container">
                        <div class="paragraph">
                            <h1>BOTON WEB</h1>
                            <div class="text-content-bigger">El comercio puede conectarse con nuestra librería web en
                                menos de 2 horas.
                                La librería web es full javascript, permitiendo la integración de cualquier tienda web
                                en cualquier lenguaje de programación.
                            </div>
                            <div class="text-content-bigger">El formulario de pago se crea dinámicamente, haciendo
                                segura la captura y el envío de los datos de tarjeta.
                                El comercio decide cómo y dónde mostrar el formulario de pago, permitiendo continuar con
                                la experiencia del cliente en la tienda web.
                            </div>
                            <div class="text-content-bigger">El pago es solicitado al procesador de pagos y la respuesta
                                es mostrada al cliente sin errores y sin demoras.
                                El comercio recibe la notificación de autorización de los servidores de Synapsis.
                            </div>
                            <div class="text-content-bigger">Descarga un ejemplo de integración completo al botón de
                                pago web, <a href="https://github.com/synapsolutions/shop-web">visita nuestro link en
                                    git.</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12">
                    <div class="img-container">
                        <img class="img" src="assets/img/web-button-form.png">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12">
                    <div class="text-container">
                        <div class="paragraph">
                            <h2>Instalación</h2>
                            <div class="text-content">La librería web es full javascript, permitiendo la integración de
                                cualquier tienda web en cualquier lenguaje de programación.
                            </div>
                            <div class="text-content">1. Importar libreria en la web
                            </div>
                            <div class="code-content-xs">
                                <!--<iframe src="https://pastebin.com/embed_iframe/g4XHivPe"
                                    style="border:none;width:100%;height:75px;padding-bottom:10px;"></iframe>-->
                                <ng-gist userName="synapdevelopment" gistId="f098dc77757cd4405d5dbf1197131c09"
                                    fileName="merchant-web-head-script.html"></ng-gist>
                            </div>
                        </div>
                        <div class="paragraph">
                            <h2>Configuración</h2>
                            <div class="text-content">1. Cree el contenedor donde se visualizará el botón de pago web.
                            </div>
                            <div class="code-content-sm">
                                <!--<iframe src="https://pastebin.com/embed_iframe/CJAkGSYN"
                                    style="border:none;width:100%;height:100px;padding-bottom:10px;"></iframe>-->
                                <ng-gist userName="synapdevelopment" gistId="baec16ff0c8a63d3257cda1b16d16ab9"
                                    fileName="merchant-web-body-div.html"></ng-gist>
                            </div>
                            <aside class="note"><b>Nota:</b> Las dimensiones de este contenedor deben ser de 350px de
                                ancho por 440px de alto.
                            </aside>
                            <div class="text-content">2. Inicialice y configure el formulario de pago (visualmente, el
                                ambiente de procesamiento de pago y la gestión visual de la respuesta).
                            </div>
                            <div class="code-content-md">
                                <!--<iframe src="https://pastebin.com/embed_iframe/1rTuStSk"
                                    style="border:none;width:100%;height:600px;padding-bottom:10px;"></iframe>-->
                                <ng-gist userName="synapdevelopment" gistId="b48660d10ecb36cb54a137fb1fadaf80"
                                fileName="merchant-web-func-startpayment.js"></ng-gist>
                            </div>
                            <div class="text-content">3. Setee los datos de la orden o pedido.
                            </div>
                            <div class="code-content-md">
                                <!--<iframe src="https://pastebin.com/embed_iframe/vx78zaBe"
                                    style="border:none;width:100%;height:600px;padding-bottom:10px;"></iframe>-->
                                <ng-gist userName="synapdevelopment" gistId="d88a802d29c709bef599fe2bab0dc287"
                                    fileName="merchant-web-func-buildTransaction.js"></ng-gist>
                                </div>
                            <div class="text-content">4. Setee los datos de autenticación. Para más información diríjase
                                a la sección de <a routerLink="/security">"Seguridad".</a>
                            </div>
                            <div class="code-content-md">
                                <!--<iframe src="https://pastebin.com/embed_iframe/T9xYvXHR"
                                    style="border:none;width:100%;height:400px;padding-bottom:10px;"></iframe>-->
                                <ng-gist userName="synapdevelopment" gistId="536b101b353108729c05da61809d6f53"
                                    fileName="merchant-web-func-buildAuthentication.js"></ng-gist>
                            </div>
                            <aside class="note"><b>Nota:</b> El APIKey y SignatureKey están disponibles en la plataforma
                                de gestión de Synapsis (Synap - Control)
                            </aside>
                            <div class="text-content">5. Las siguientes funciones son utilitarias y deben ser
                                utilizadas solo como "referencia".
                            </div>
                            <div class="code-content-md">
                                <!--<iframe src="https://pastebin.com/embed_iframe/VPfbEaw1"
                                    style="border:none;width:100%;height:400px;padding-bottom:10px;"></iframe>-->
                                <ng-gist userName="synapdevelopment" gistId="1d207467e7f12093b2f0365d8b13baad"
                                    fileName="merchant-web-func-utilities.js"></ng-gist>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>