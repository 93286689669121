<mat-toolbar>
    <!-- <app-header class="mat-elevation-z6"></app-header> -->
    <mat-toolbar-row class="sub-toolbar">
        <button class="synap-button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()"
            *ngIf="isHandset$ | async">
            <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
        </button>
        <div class="synap-logo">
            <img class="synap-logo-img" src="../../assets/img/synapsis_dark_logo.png">
        </div>
        <div class="sub-title">
            <span>Synapsis - Docs</span>
        </div>
    </mat-toolbar-row>
</mat-toolbar>

<mat-sidenav-container class="sidenav-container" #sidenavContainer>
    <mat-sidenav #drawer class="sidenav" [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
        [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="(isHandset$ | async) === false">

        <mat-nav-list>
            <app-menu-item *ngFor="let menuItem of menuList" [menuItem]="menuItem"></app-menu-item>
        </mat-nav-list>

    </mat-sidenav>

    <mat-sidenav-content class="sidenav-content">

        <router-outlet (activate)="onActivate($event)"></router-outlet>

    </mat-sidenav-content>
</mat-sidenav-container>