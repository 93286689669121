import { NgModule } from '@angular/core';
import { Routes, RouterModule, GuardsCheckStart, GuardsCheckEnd } from '@angular/router';
import { MainComponent } from './content/pages/main/main.component';
import { PaymentsComponent } from './content/pages/payments/payments.component';
import { WebButtonComponent } from './content/pages/conexion/web-button/web-button.component';
import { IosButtonComponent } from './content/pages/conexion/ios-button/ios-button.component';
import { AndroidButtonComponent } from './content/pages/conexion/android-button/android-button.component';
import { SecurityComponent } from './content/pages/security/security.component';
import { BotComponent } from './content/pages/bot/bot.component';
import { LinkComponent } from './content/pages/link/link.component';
import { ApiAuthenticationComponent } from './content/pages/api/api-authentication/api-authentication.component';
import { ApiOrderComponent } from './content/pages/api/api-order/api-order.component';
import { ApiWebhookComponent } from './content/pages/api/api-webhook/api-webhook.component';
import { ControlIntroductionComponent } from './content/pages/control/control-introduction/control-introduction.component';
import { ControlDashboardComponent } from './content/pages/control/control-dashboard/control-dashboard.component';
import { ControlOrderComponent } from './content/pages/control/control-order/control-order.component';
import { ControlPaymentsComponent } from './content/pages/control/control-payments/control-payments.component';
import { ApiPaymentBankComponent } from './content/pages/api/api-payment-bank/api-payment-bank.component';
import { BankComponent } from './content/pages/bank/bank.component';
import { ApiPaymentCardComponent } from './content/pages/api/api-payment-card/api-payment-card.component';
import { ApiNotificationComponent } from './content/pages/api/api-notification/api-notification.component';

const routes: Routes = [
  { path: '', component: MainComponent},
  { path: 'main', component: MainComponent},
  { path: 'payments', component: PaymentsComponent},
  { path: 'development', component: WebButtonComponent},
  { path: 'web-button', component: WebButtonComponent},
  { path: 'ios-button', component: IosButtonComponent},
  { path: 'android-button', component: AndroidButtonComponent},
  { path: 'security', component: SecurityComponent},
  { path: 'bot', component: BotComponent},
  { path: 'link', component: LinkComponent},
  { path: 'bank', component: BankComponent},
  { path: 'api-authentication', component: ApiAuthenticationComponent},
  { path: 'api-order', component: ApiOrderComponent},
  { path: 'api-payment-card', component: ApiPaymentCardComponent},
  { path: 'api-payment-bank', component: ApiPaymentBankComponent},
  { path: 'api-notification', component: ApiNotificationComponent},
  { path: 'api-webhook', component: ApiWebhookComponent},
  { path: 'control-introduction', component: ControlIntroductionComponent},
  { path: 'control-dashboard', component: ControlDashboardComponent},
  { path: 'control-order', component: ControlOrderComponent},
  { path: 'control-payment', component: ControlPaymentsComponent},  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
