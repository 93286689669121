import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-control-introduction',
  templateUrl: './control-introduction.component.html',
  styleUrls: ['./control-introduction.component.scss']
})
export class ControlIntroductionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
