import { Component, OnInit } from '@angular/core';

declare const SwaggerUIBundle: any;

@Component({
  selector: 'app-api-notification',
  templateUrl: './api-notification.component.html',
  styleUrls: ['./api-notification.component.scss']
})
export class ApiNotificationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    const ui = SwaggerUIBundle({
      dom_id: '#notification-swagger-ui',
      layout: 'BaseLayout',
      presets: [
        SwaggerUIBundle.presets.apis,
        SwaggerUIBundle.SwaggerUIStandalonePreset
      ],
      url: '/assets/json/notification-swagger.json',
      // url: '/assets/json/test.json',
    });
  }

}
