<div class="row center-xs">
    <div class="col-xs-12 col-sm-11 col-md-9">
        <div class="row">
            <div class="col-xs-12">
                <div class="text-container">
                    <div class="paragraph">
                        <h1>SEGURIDAD</h1>
                        <div class="text-content-bigger">Las conexiones con las librerías de pago web y móvil utilizan
                            un APIKey y un SignatureKey para autenticar solicitudes y garantizar la integridad del mensaje.
                        </div>
                        <div class="text-content-bigger">
                            El APIKey y SignatureKey pueden ser visualizadas en la plataforma de gestión de Synapsis.
                        </div>
                        <div class="text-content-bigger">
                            El APIKey y SignatureKey tienen muchos privilegios, ¡asegúrese de mantenerlas seguras! No
                            las comparta en áreas de acceso público como GitHub, código del lado del cliente, etc.
                        </div>
                        <div class="text-content-bigger">
                            La autenticación en la conexión con las librerías se realiza enviando el APIKey y
                            Signature en las funciones de construcción de autenticación de las librerías, siendo el
                            APIKey el valor de identificador de empresa y la Signature el valor cifrado de campos clave con la
                            SignatureKey.
                        </div>
                        <div class="text-content-bigger">
                            Las solicitudes de conexión con las librerías sin autenticación fallarán.
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-12">
                <div class="img-container">
                    <img class="img-bigger" src="assets/img/security.png">
                </div>
            </div>
        </div>
    </div>
</div>