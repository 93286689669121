<div class="row center-xs main-container">
    <div class="col-xs-12 col-sm-11 col-md-9">
        <div class="row">
            <div class="col-xs-12">
                <div class="text-container">
                    <div class="paragraph">
                        <h1>PAGOS OMNICANALES</h1>
                        <div class="text-content-bigger">Desde que afilias tu comercio en Synapsis, puedes usar todos
                            nuestros canales de pago y combinarlos según lo necesites.
                        </div>
                        <div class="text-content-bigger">Tus clientes pueden pagar desde la web, móvil, bot y
                            link, pudiendo además enviar recordatorios de pago a tus clientes via redes sociales,
                            correo, sms o cualquier medio que acepte el envío de URLs con un link de pago,
                            recuperando la venta de manera aumatizada.
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-12">
                <div class="img-container">
                    <img class="img-bigger" src="assets/img/canales-pago.png">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12 col-md-5">
                <div class="text-container">
                    <div class="paragraph">
                        <h2>PRUEBA UN PAGO</h2>
                        <div class="text-content">Prueba el pago seleccionando el botón de pago con tarjeta, cuando se
                            muestre el formulario ingresa cualquier tarjeta de
                            prueba:
                        </div>
                        <div class="button-content">
                            <button mat-raised-button class="button" (click)="openDialog()">Pago con tarjeta</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-md-7">
                <div class="table-content">
                    <mat-table [dataSource]="dataSource" class="mat-elevation-z8">

                        <!-- Brand Column -->
                        <ng-container matColumnDef="brand">
                            <mat-header-cell *matHeaderCellDef>Marca</mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                <span class="mobile-label">Marca</span>
                                {{element.brand}}
                            </mat-cell>
                        </ng-container>

                        <!-- Number Column -->
                        <ng-container matColumnDef="number">
                            <mat-header-cell *matHeaderCellDef>Número de tarjeta</mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                <span class="mobile-label">Número de tarjeta</span>
                                {{element.number}}
                            </mat-cell>
                        </ng-container>

                        <!-- Date Column -->
                        <ng-container matColumnDef="date">
                            <mat-header-cell *matHeaderCellDef>Vence</mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                <span class="mobile-label">Vence</span>
                                {{element.date}}
                            </mat-cell>
                        </ng-container>

                        <!-- CVV2 Column -->
                        <ng-container matColumnDef="cvv2">
                            <mat-header-cell *matHeaderCellDef>CVV2</mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                <span class="mobile-label">CVV2</span>
                                {{element.cvv2}}
                            </mat-cell>
                        </ng-container>

                        <!-- Type Column -->
                        <ng-container matColumnDef="type">
                            <mat-header-cell *matHeaderCellDef>Tipo</mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                <span class="mobile-label">Tipo</span>
                                {{element.type}}
                            </mat-cell>
                        </ng-container>

                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                    </mat-table>
                </div>
            </div>
        </div>
    </div>
</div>